import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';

// import moment from 'moment';
import moment from 'moment-timezone';
import {useFormik} from 'formik';
import {Row, Col, Container, Spinner, Table} from 'react-bootstrap';
import {useLocation, useHistory, useParams} from 'react-router-dom';
import {useQuery, useMutation} from '@apollo/client';

// GraphQL
import {GET_LOCATIONS} from 'graphql/queries/locations';
import {
    GET_NUDGE,
    GET_NUDGES_RECEIVED,
    GET_NUDGES_TAPPED,
    GET_NUDGE_SCORE,
    GET_NUDGE_ANALYTICS,
    GET_NUDGE_AND_CAMPAIGN_CATEGORIES
} from 'graphql/queries/nudges';

import {
    CREATE_TIME_BASED_NUDGE_MUTATION,
    CREATE_GEOLOCATION_NUDGE_MUTATION,
    UPDATE_TIME_BASED_NUDGE_MUTATION,
    UPDATE_GEOLOCATION_NUDGE_MUTATION,
} from 'graphql/mutations/nudges';
import {GET_ALL_FEATURE_FLAGS_WITH_USER_ID, GET_ORGANIZATION} from 'graphql/queries/organizations';
import {GET_SEGMENTS, GET_SEGMENT} from 'graphql/queries/segments';

// Services
import NudgeService from 'services/NudgeService';
import LocationService from 'services/LocationService';
import OrganizationService from 'services/OrganizationService';
import SegmentService from 'services/SegmentService';

import Analytics from 'libs/Analytics';

import {createOrUpdateNudgeService} from 'machines/nudges/createOrUpdate.machine';
import {useAuthMachineValue} from 'contexts/auth-machine.context.ts';

// Components
import InitialLoader from 'components/InitialLoader/InitialLoader';
import PageHeader from 'components/PageHeader/PageHeader';
import CustomSection from 'components/CustomSection/CustomSection';
import CustomButton from 'components/CustomButton/CustomButton';
import NotificationPreview from 'components/NotificationPreview/NotificationPreview';
import NudgeScore from 'components/NudgeScore/NudgeScore';
import CustomInput from 'components/CustomInput/CustomInput';
import EmojiTextInput from 'components/EmojiTextInput/EmojiTextInput';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import SelectableFilter from 'components/SelectableFilter/SelectableFilter';
import {useNotification} from 'components/Notification/Notification';
import NudgeAnalyticsData from "../NudgeAnalyticsData/NudgeAnalyticsData";
import ButtonImage from './nudge-Assist-transparent.png'; // Import the image

// Utils
import {
    buildQueryParamsURL,
    getLocationsFromConditions,
    getDateAndTimeFromConditions,
    getUserSegmentFromList,
    ALL_POSSIBLE_RECIPIENTS_OPTION,
    formatNumber,
    getUserSegmentDropdownOptions,
    getUserFeature,
    getUser,
    getTrellanceSegmentFromList,
    getTrellanceSegmentDropdownOptions,
    getHubSpotSegmentFromList,
    getHubSpotSegmentDropdownOptions,
    formatNumberPercentage
} from 'Utils';

// Constants
import {
    CONDITION_TYPES,
    ENVIRONMENTS,
    GEOFENCE_PRESETS,
    INPUT_DATE_FORMAT,
    INPUT_TIME_FORMAT,
    NUDGE_TYPES,
    rfc1738UrlRegex,
    WEEKDAY_OPTIONS,
    TIME_HOUR,
    TIME_MINUTE,
    TIME_TYPE,
    ROUTES,
    DWELL_PRESETS,
    BYPASS_LIMIT_OPTIONS,
    MAX_FREQ_OPTIONS,
    NUDGE_TAP_BEHAVIOR, MESSAGE_LINK_OPTIONS
} from 'Constants';
import Larky from '../../../../components/Larky';

import {
    getDateTimeObject,
    getDateTimeObjectWithUpdate,
    isLocationNudgeExpired,
    isTimeBasedNudgeExpired,
} from './CreateOrUpdateNudge.utils';

import validationSchema from './CreateOrUpdateNudge.validator';

// Styles
import styles from './CreateOrUpdateNudge.module.scss';
import AnalyticsModal from '../AnalyticsModal/AnalyticsModal';
import NudgeAssistModal from '../NudgeAssistModal/NudgeAssistModal';
import {NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD} from '../../../../Constants';
import {GET_NUDGES_HEADERS} from '../../../../graphql/queries/nudges';
import {COUNT_ALL_SEGMENTS} from '../../../../graphql/queries/segments';
import {useInitialFocus} from '../../../../hooks/useInitialFocus';
import CustomRadioButton from "../../../../components/CustomRadioButton/CustomRadioButton";
import UserService from "../../../../services/UserService";
import {downloadAnalyticsDB} from "../../../../DashboardAnalyticsUtils";
import ReactTooltip from "react-tooltip";
import MessagingReachLoading from "../../../Home/components/MessagingReach/MessagingReachLoading";
import ToolTipIcon from "../../../../components/ToolTipIcon/ToolTipIcon";

// Local Constants
const FILE_NAME = 'CreateOrUpdateNudge.jsx';

// TODO move the following code to a common file, so it can be access from other components
const TimeLabel = ({title, style, children}) => (
    <div className={styles['time-label']} style={style}>
        <h6>{title}</h6>
        {children}
    </div>
);

const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getDisplayTimezoneName = (timezone) => {
  const offset = moment.tz(timezone).format('Z');
  const abbreviation = moment.tz(timezone).format('z');
  // Create a more user-friendly name
  const timezoneMap = {
    'EDT': 'Eastern Time',
    'EST': 'Eastern Time',
    'CDT': 'Central Time',
    'CST': 'Central Time',
    'MDT': 'Mountain Time',
    'MST': 'Mountain Time',
    'PDT': 'Pacific Time',
    'PST': 'Pacific Time',
    // Add more mappings as needed
  };
  return timezoneMap[abbreviation] || `UTC${offset}`; // Default to UTC offset if no mapping found
};

const timezone = getUserTimezone();
const TIME_ZONE = getDisplayTimezoneName(timezone);

TimeLabel.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
};

TimeLabel.defaultProps = {
    style: {},
};

// global to check for changes when updating
let nudgeDetails;

function getConditionsForNudge(conditions) {
    const serverTimeFormat = 'HH:mm:ss';
    const locationConditions = {
        activeWindows: [],
        geofences: [],
    };

    if (!conditions || conditions.length === 0) {
        return null;
    }

    for (let i = 0; i < conditions.length; i += 1) {
        const condition = conditions[i];
        switch (Number(condition.conditionType.id)) {
            case CONDITION_TYPES.ACTIVE_WINDOW.ID:
                locationConditions.activeWindows.push(condition);
                Object.assign(locationConditions, {
                    timeRangeStart: moment(condition.timeRangeStart, serverTimeFormat),
                    timeRangeEnd: moment(condition.timeRangeEnd, serverTimeFormat),
                });
                break;
            case CONDITION_TYPES.GEOFENCE.ID:
                locationConditions.geofences.push(condition);
                Object.assign(locationConditions, {radius: condition.radius});
                Object.assign(locationConditions, {delay: condition.delay});
                break;
            case CONDITION_TYPES.DATE_TIME_RANGE.ID:
                Object.assign(locationConditions, {
                    dateTimeRangeId: Number(condition.id),
                    dateTimeRangeStart: moment(`${condition.dateTimeRangeStart}`),
                    dateTimeRangeEnd: moment(`${condition.dateTimeRangeEnd}`),
                });
                break;
            default:
                break;
        }
    }

    return locationConditions;
}

const castDateTimeToNull = (date) => (typeof date === 'undefined' || !date.isValid() ? null : date);

const extractHourValue = (momentDate) => {
    const hourValue = Number(momentDate.format('HH'));
    return hourValue >= 12 ? hourValue - 12 : hourValue;
};

function CreateOrUpdateNudge({organizationId}) {
    const location = useLocation();
    const history = useHistory();
    const {actionId} = useParams();
    const [currentAuth] = useAuthMachineValue();
    const ENVIRONMENT = currentAuth.context.user.environment;
    const {user} = currentAuth.context;
    useInitialFocus();

    const [createTimeBasedNudgeMutation] = useMutation(CREATE_TIME_BASED_NUDGE_MUTATION);
    const [createGeolocationNudgeMutation] = useMutation(CREATE_GEOLOCATION_NUDGE_MUTATION);
    const [updateTimeBasedNudgeMutation] = useMutation(UPDATE_TIME_BASED_NUDGE_MUTATION);
    const [updateGeolocationNudgeMutation] = useMutation(UPDATE_GEOLOCATION_NUDGE_MUTATION);
    const getNudgesReceivedQuery = useQuery(GET_NUDGES_RECEIVED, {skip: true});
    const getNudgesTappedQuery = useQuery(GET_NUDGES_TAPPED, {skip: true});
    const getNudgesHeadersQuery = useQuery(GET_NUDGES_HEADERS, {skip: true});
    const getNudgeScoreQuery = useQuery(GET_NUDGE_SCORE, {skip: true});
    const getNudgeAnalyticsQuery = useQuery(GET_NUDGE_ANALYTICS, {skip: true})

    const getLocationsQuery = useQuery(GET_LOCATIONS, {skip: true});
    const getNudgeQuery = useQuery(GET_NUDGE, {skip: true});
    const getSegmentsQuery = useQuery(GET_SEGMENTS, {skip: true});
    const getSegmentQuery = useQuery(GET_SEGMENT, {skip: true});
    const getOrganizationQuery = useQuery(GET_ORGANIZATION, {skip: true});
    const getCountAllSegmentsQuery = useQuery(COUNT_ALL_SEGMENTS, {skip: true});
    const getNudgeMessageCategories = useQuery(GET_NUDGE_AND_CAMPAIGN_CATEGORIES, {skip: true})
    const getAllFeatureFlagsQuery = useQuery(GET_ALL_FEATURE_FLAGS_WITH_USER_ID, {skip: true});

    const params = new URLSearchParams(location.search);
    const urlNudgeType = Number(decodeURIComponent(params.get('nudgeType') || 2));
    const isUpdate = typeof actionId !== 'undefined';
    const nudgeService = new NudgeService();
    const locationsService = new LocationService();
    const notification = useNotification();

    const [locations, setLocations] = useState(null);
    const [currentNudge, setCurrentNudge] = useState(null);
    const [initialValues, setInitialValues] = useState({});
    const {sent, isDraft, nudgeType} = initialValues;
    const [radioOptions, setRadioOptions] = useState([
        {
            value: 0,
            label: `Don't resend this message in the future for repeat visits to the selected location(s)`,
            selected: true
        },
        {value: 1, label: 'Keep sending this message for repeat visits in the future', selected: false},
    ]);
    const [userSegmentList, setUserSegmentList] = useState([]);
    const [trellanceSegmentList, setTrellanceSegmentList] = useState([]);
    const [hubspotSegmentList, setHubSpotSegmentList] = useState([]);
    const [maxFreqOptions, setMaxFreqOptions] = useState([]);
    const [messageLinkOptions,setmessageLinkOptions] = useState([]);
    const [dwellOptions, setDwellOptions] = useState([]);
    const [geoLocationFlag, setGeoLocationFlag] = useState(false);
    const [messageCategoryOptions, setMessageCategoryOptions] = useState([]);
    const [categoryWarning, setCategoryWarning] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [showNudgeAssistModal, setNudgeAssistShowModal] = useState(false);
    const [modalKey, setModalKey] = useState(0);
    const [userHasGeoNudgesEdit, setUserHasGeoNudgesEdit] = useState(false);
    const [userHasTimeNudgesEdit, setUserHasTimeNudgesEdit] = useState(false);
    const [userHasDeepLink, setUserHasDeepLink] = useState(false);
    const [userHasMLFeatureFlag, setUserHasMLFeatureFlag] = useState(null);
    const [userHasExportAnalytics, setUserHasExportAnalytics] = useState(false);
    const [userHasNudgeAssist, setUserNudgeAssist] = useState(false);
    const [NudgeAssistToolTipText, setNudgeAssistToolTipText] = useState(false);
    const [NudgeAssistUrl, setNudgeAssistUrl] = useState(false);
    const [titleInputInFocus, setTitleInputInFocus] = useState(false);
    const [messageInputInFocus, setMessageInputInFocus] = useState(false);
    const [nudgeScoreCalculating, setNudgeScoreCalculating] = useState(false);
    const [nudgeassist, setNudgeAssist] = useState(true);
    const [nudgeScoreData, setNudgeScoreData] = useState(null);
    const [nudgeAnalyticsData, setNudgeAnalyticsData] = useState(null);
    const [nudgeAnalyticsDataLoading, setNudgeAnalyticsDataLoading] = useState(false);
    const [nudgeAnalyticsDataError, setNudgeAnalyticsDataError] = useState(false);
    const [exportAnalyticsToolTipText, setExportAnalyticsToolTipText] = useState(false);
    const [exportAnalyticsUrl, setExportAnalyticsUrl] = useState(false);
    const [exportAnalyticsLoading, setExportAnalyticsLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({ option1: '', option2: '' });
    const [userHasTrellanceSegmentationPermission, setUserHasTrellanceSegmentationPermission] = useState(false);
    const [userHashubSpotSegmentationPermission, setUserHasHubSpotSegmentationPermission] = useState(false);


    const handleRadioButtonChange = (value) => {
        formik.setFieldValue('tapBehavior', value);
        const updatedRadioOptions = radioOptions.map((option) => {
            if (option.value === value) {
                option.selected = true;
            }
            if (option.value !== value) {
                option.selected = false;
            }
            return option;
        });
        setRadioOptions(updatedRadioOptions)
    };

    // effects to be executed once only
    useEffect(async () => {
        async function fetchUserSegments() {
            try {
                const userSearchParams = {
                    segmentType: 'user-file',
                };
                const userSegments = await SegmentService.getSegments(
                    getSegmentsQuery, userSearchParams,
                );
                await setUserSegmentList(getUserSegmentDropdownOptions(userSegments.hits));
            } catch (err) {
                Analytics.trackApplicationError(err.message, FILE_NAME, 'fetchUserSegments');
                notification.alert('Failed to fetch user segments', 'danger');
            }
        }

        async function fetchTrellanceSegments() {
            try {
                const trellanceSearchParams = {
                    segmentType: 'trellance',
                };
                const trellanceSegments = await SegmentService.getSegments(
                    getSegmentsQuery, trellanceSearchParams,
                );
                await setTrellanceSegmentList(getTrellanceSegmentDropdownOptions(trellanceSegments.hits));
            } catch (err) {
                Analytics.trackApplicationError(err.message, FILE_NAME, 'fetchTrellanceSegments');
                notification.alert('Failed to fetch Trellance segments', 'danger');
            }
        }

        async function fetchhubspotSegments() {
            try {
                const hubSpotSearchParams = {
                    segmentType: 'hubspot',
                };

                const hubspotSegments = await SegmentService.getSegments(
                    getSegmentsQuery, hubSpotSearchParams,
                );
                await setHubSpotSegmentList(getHubSpotSegmentDropdownOptions(hubspotSegments.hits))
            } catch (err) {
                Analytics.trackApplicationError(err.message, FILE_NAME, 'fetchTrellanceSegments');
                notification.alert('Failed to fetch Hubspot segments', 'danger');
            }
        }

        await fetchUserSegments();
        await fetchTrellanceSegments();
        await fetchhubspotSegments();
    }, []);

    const determineNudgeScore = async (field) => {
        //This will call on a reset of nudge type
        if (field === 'typeChange') {
            setNudgeScoreCalculating(true);
            setNudgeAssist(true);
            const nudgeScoreResponse = await NudgeService.getNudgeScore(
                getNudgeScoreQuery,
                ' ',
                ' '
            );
            setNudgeScoreData(nudgeScoreResponse)
            setNudgeScoreCalculating(false)
            setNudgeAssist(false);
            return null;
        }

        if (field === 'out' || !field) {
            setNudgeScoreCalculating(true);
            setNudgeAssist(true);
            //This will call on updating of the body/title fields to set up nudge ML score info
            if (formik.values.body || formik.values.title) {
                const nudgeScoreResponse = await NudgeService.getNudgeScore(
                    getNudgeScoreQuery,
                    formik.values.body || ' ',
                    formik.values.title || ' '
                );
                setNudgeScoreData(nudgeScoreResponse)
                setNudgeScoreCalculating(false)
                setNudgeAssist(false);
                return null;
            }

            //This will call on loading of the page to set up nudge ML score info
            if (initialValues.body || initialValues.title) {
                const nudgeScoreResponse = await NudgeService.getNudgeScore(
                    getNudgeScoreQuery,
                    initialValues.body || ' ',
                    initialValues.title || ' '
                );
                setNudgeScoreData(nudgeScoreResponse)
                setNudgeScoreCalculating(false)
                return null;
            }

            // This will call when initially creating a nudge to set up nudge ML score info
            const nudgeScoreResponse = await NudgeService.getNudgeScore(
                getNudgeScoreQuery,
                formik.values.body || ' ',
                formik.values.title || ' '
            );
            setNudgeScoreData(nudgeScoreResponse)
            setNudgeScoreCalculating(false)
            // setNudgeAssist(false);
            return null;
        }
        return null;
    }
    useEffect(async () => {
        await determineNudgeScore(titleInputInFocus)
    }, [titleInputInFocus])

    useEffect(async () => {
        await determineNudgeScore(messageInputInFocus)
    }, [messageInputInFocus])

    useEffect(async () => {
        await determineNudgeScore('typeChange')
    }, [urlNudgeType])

    useEffect(async () => {
        await determineNudgeScore()
    }, [initialValues])

    useEffect(async () => {
        const hasExportAnalytics = await getUserFeature('export_analytics');
        await setUserHasExportAnalytics(!!hasExportAnalytics);
    }, [user.featureFlags]);


    const [userNudgeAssist, setuserNudgeAssist] = useState(false);

    useEffect(async () => {
    const hasfeaturenudgeassist = await getUserFeature('nudge_assist');
    await setuserNudgeAssist(!!hasfeaturenudgeassist);
    }, [user.featureFlags]);

    useEffect(async () => {
    const HasNudgeAssist = await UserService.getFeatureFlagInfoWithUserId(getAllFeatureFlagsQuery, user.id, 'nudge_assist');
    setNudgeAssistToolTipText(HasNudgeAssist.tooltip);
    setNudgeAssistUrl(HasNudgeAssist.tooltipUrl);
    }, [user.id]);

    const createButtonToolTipText = (textBody, url) => `${NudgeAssistToolTipText} Click to learn more!`;


    useEffect(async () => {
        const exportAnalyticsFeatureInfo = await UserService.getFeatureFlagInfoWithUserId(getAllFeatureFlagsQuery, user.id, 'export_analytics');
        setExportAnalyticsToolTipText(exportAnalyticsFeatureInfo.tooltip);
        setExportAnalyticsUrl(exportAnalyticsFeatureInfo.tooltipUrl);
    }, [user.id]);

    const onExportAnalyticsClick = () => {
        if (userHasExportAnalytics) {
            const downloadAnalyticsParams = getAnalyticsModalParams()
            return downloadAnalyticsDB(downloadAnalyticsParams, setExportAnalyticsLoading);
        }
        if (!userHasExportAnalytics) {
            window.open(exportAnalyticsUrl, '_blank');
        }
        return null;
    };

    const retrieveNudgeAnalytics = async (actionId) => {
        const nudgeAnalyticsDataResponse = await NudgeService.getNudgeAnalytics(
            getNudgeAnalyticsQuery,
            actionId
        );
        await setNudgeAnalyticsData(nudgeAnalyticsDataResponse)
        return null;
    }

    useEffect(async () => {
        if (!isDraft || isUpdate) {
            // Call time based nudge analytics logic
            await setNudgeAnalyticsDataLoading(true);
            try {
                await retrieveNudgeAnalytics(actionId);
            }catch (e) {
                setNudgeAnalyticsDataError(true)
            }
            await setNudgeAnalyticsDataLoading(false);
        }
        return () => {};
    }, [actionId, isUpdate, isDraft])

    useEffect(async () => {
        const hasNudgeFeatureTime = await getUserFeature('nudges_timed', 2);
        const hasNudgeFeatureGeoWrite = await getUserFeature('geolocations', 2);
        const hasNudgeFeatureGeoRead = await getUserFeature('geolocations', 1);
        const hasMLScoreFeatureBasic = await getUserFeature('ml_nudge_score_model');
        const hastrellanceSegments = await getUserFeature('trellance_segmentation');
        const hashubspotSegments = await getUserFeature('hubspot_segmentation');
        const hasdeeplink = await getUserFeature('Deeplink');
        await setGeoLocationFlag(hasNudgeFeatureGeoRead || hasNudgeFeatureGeoWrite);
        await setUserHasMLFeatureFlag(hasMLScoreFeatureBasic);
        await setUserHasGeoNudgesEdit(hasNudgeFeatureGeoWrite);
        await setUserHasTimeNudgesEdit(hasNudgeFeatureTime);
        await setUserHasDeepLink(hasdeeplink);
        await setUserHasTrellanceSegmentationPermission(hastrellanceSegments);
        await setUserHasHubSpotSegmentationPermission(hashubspotSegments);
    }, []);


    // effects to be executed whenever the listed dependencies change
    useEffect(async () => {
        async function fetchLocations(type) {
            if (type === NUDGE_TYPES.GEOFENCE.ID && organizationId !== null && locations === null) {
                try {
                    const locationsResponse = await LocationService.getByOrganization(
                        getLocationsQuery,
                        organizationId,
                    );
                    const locs = locationsResponse.reduce((result, locationDetails) => {
                        if (locationDetails.locationGroupId === null) {
                            let label = locationDetails.name;
                            if (locationDetails.isLocationGroup) {
                                const locationCount = (locationDetails.address && !Number.isNaN(locationDetails.address)) ? formatNumber(locationDetails.address) : '';
                                const locationText = locationCount === '1' ? 'location' : 'locations';
                                label = `${locationDetails.name} (${locationCount} ${locationText})`;
                            }
                            result.push({
                                value: locationDetails.id,
                                label,
                            });
                        }
                        return result;
                    }, []);
                    // eslint-disable-next-line no-nested-ternary,max-len
                    locs.sort((a, b) => (a.label.toLowerCase() === b.label.toLowerCase() ? 0 : a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
                    setLocations(locs);
                } catch (err) {
                    Analytics.trackApplicationError(err.message, FILE_NAME, 'fetchLocations');
                    notification.alert('Failed to fetch locations', 'danger');
                }
            }
        }

        async function fetchOrganizationInfo(organizationId) {
            try {
                // get correct options for Testing vs Non-testing organizations
                const user = getUser();
                if (user.currentOrganization && user.currentOrganization.organizationType) {
                    if (user.currentOrganization.organizationType.isProduction === true) {
                        await setMaxFreqOptions(MAX_FREQ_OPTIONS.filter(
                            (option) => option.value != 60,
                        ));
                        const filteredDwellPresets = DWELL_PRESETS.filter(
                            (option => {
                                return !option.isTest;
                            })
                        );
                        await setDwellOptions(filteredDwellPresets);
                        await setmessageLinkOptions(MESSAGE_LINK_OPTIONS)
                    } else {
                        await setMaxFreqOptions(MAX_FREQ_OPTIONS);
                        await setDwellOptions(DWELL_PRESETS)
                        await setmessageLinkOptions(MESSAGE_LINK_OPTIONS)
                    }
                } else {
                    await setMaxFreqOptions(MAX_FREQ_OPTIONS.filter(
                        (option) => option.value != 60,
                    ));
                    await setDwellOptions(DWELL_PRESETS.filter(
                        (option => !option.isTest)
                    ))
                    await setmessageLinkOptions(MESSAGE_LINK_OPTIONS)
                }
            } catch (err) {
                Analytics.trackApplicationError(err.message, FILE_NAME, 'fetchOrganizationInfo');
                notification.alert('Failed to fetch organization info', 'danger');
            }
        }

        async function fetchNudge() {
            if (isUpdate && Object.keys(initialValues).length === 0) {
                try {

                    nudgeDetails = await NudgeService.getNudge(getNudgeQuery, actionId);
                    setCurrentNudge(nudgeDetails);

                    if (!nudgeDetails) {
                        history.push('/nudges/main');
                    }

                    const {isDraft, archived} = nudgeDetails;

                    const locationsParsed = getLocationsFromConditions(
                        nudgeDetails.conditions,
                    );
                    const dateAndTime = getDateAndTimeFromConditions(
                        nudgeDetails.conditions,
                    );
                    const userSegment = getUserSegmentFromList(
                        userSegmentList,
                        Number(nudgeDetails.message.segmentId),
                    );

                    const trellanceSegment = getTrellanceSegmentFromList(
                        trellanceSegmentList,
                        Number(nudgeDetails.message.segmentId),
                    );

                    const hubspotSegment = getHubSpotSegmentFromList(
                        hubspotSegmentList,
                        Number(nudgeDetails.message.segmentId),
                    );

                    const newInitialValues = {
                        actionId: Number(nudgeDetails.id),
                        messageId: Number(nudgeDetails.messageId),
                        name: nudgeDetails.message.name,
                        description: nudgeDetails.message.description,
                        title: nudgeDetails.message.title,
                        body: nudgeDetails.message.body,
                        segmentId: trellanceSegment ? trellanceSegment : (hubspotSegment ? hubspotSegment : userSegment),
                        url: nudgeDetails.message.url,
                        suppress: nudgeDetails.message.suppressMotification,
                        schedDate: dateAndTime.date,
                        schedTime: dateAndTime.time,
                        conditions: nudgeDetails.conditions,
                        locations: locationsParsed,
                        isDraft,
                        archived,
                        timeRangeStart: null,
                        timeRangeEnd: null,
                        sent: nudgeDetails.sent,
                        scheduleTimeHour: dateAndTime.time ? {
                            value: dateAndTime.time.slice(0, 2),
                            label: dateAndTime.time.slice(0, 2)
                        } : {value: 10, label: '10'},
                        scheduleTimeMinute: dateAndTime.time ? {
                            value: dateAndTime.time.slice(3, 5),
                            label: dateAndTime.time.slice(3, 5)
                        } : {value: 0, label: '00'},
                        scheduleTimeType: dateAndTime.time ? {
                            value: dateAndTime.time.slice(6, 8),
                            label: dateAndTime.time.slice(6, 8)
                        } : TIME_TYPE[0],
                        timeRangeHourStart: null,
                        timeRangeMinuteStart: null,
                        timeRangeTypeStart: null,
                        timeRangeHourEnd: null,
                        timeRangeMinuteEnd: null,
                        timeRangeTypeEnd: null,
                        tapBehavior: nudgeDetails.message.tapBehavior ? 1 : 0,
                        messageCapLimitOverride: BYPASS_LIMIT_OPTIONS.find(
                            ({value}) => value === nudgeDetails.message.messageCapLimitOverride,
                        ),
                        messageMaxFreq: MAX_FREQ_OPTIONS.find(
                            ({value}) => value === nudgeDetails.message.messageMaxFreq,
                        ),
                        urlLinkType: MESSAGE_LINK_OPTIONS.find(
                            ({value}) => value === nudgeDetails.message.urlLinkType
                        ),
                        messageCategory: nudgeDetails.message.messageCategoryId ? messageCategoryOptions.find(
                            ({value}) => value === nudgeDetails.message.messageCategoryId) : null
                    };

                    if (nudgeDetails.message.url) {
                        Object.assign(newInitialValues, {url: nudgeDetails.message.url});
                    }

                    // if (nudgeDetails.message.urlLinkType) {
                    //     Object.assign(newInitialValues, {urlLinkType: nudgeDetails.message.urlLinkType});
                    // }

                    if (locationsParsed.length > 0) {
                        const conditions = getConditionsForNudge(nudgeDetails.conditions);
                        Object.assign(newInitialValues, {
                            nudgeType: NUDGE_TYPES.GEOFENCE.ID,
                            conditions,
                        });
                        if (conditions !== null) {
                            Object.assign(newInitialValues, {
                                nudgeType: NUDGE_TYPES.GEOFENCE.ID,
                                selectedLocations: conditions.geofences.filter((geofence) => (
                                    geofence.location
                                )).map((geofence) => ({
                                    value: Number(geofence.location.id),
                                    label: geofence.location.name,
                                })),
                                selectedWeekDays: conditions.activeWindows.map(
                                    (activeWindow) => WEEKDAY_OPTIONS[activeWindow.dayOfWeek - 1],
                                ),
                                radius: GEOFENCE_PRESETS.find(
                                    ({value}) => value === conditions.radius,
                                ),
                                dateTimeRangeStart: castDateTimeToNull(
                                    conditions.dateTimeRangeStart,
                                ),
                                dateTimeRangeEnd: castDateTimeToNull(
                                    conditions.dateTimeRangeEnd,
                                ),
                                timeRangeStart: castDateTimeToNull(conditions.timeRangeStart),
                                timeRangeEnd: castDateTimeToNull(conditions.timeRangeEnd),
                                delay: DWELL_PRESETS.find(
                                    ({value}) => value === conditions.delay,
                                ),
                            });

                            // since we remove the time part from dateTimeRangeStart, there's no way to now if
                            // Today/dateTimeRangeStartNow is true or false.
                            const today = moment().format('DD-MM-YYYY');
                            const dateStart = moment(newInitialValues.dateTimeRangeStart).format('DD-MM-YYYY');
                            let isToday = false;
                            if (today === dateStart) {
                                isToday = true;
                                // newInitialValues.dateTimeRangeStart = null;
                            }

                            Object.assign(newInitialValues, {
                                dateTimeRangeStartNow: isToday,
                                dateTimeRangeEndNever:
                                    newInitialValues.dateTimeRangeEnd === null,
                                allDay:
                                    newInitialValues.timeRangeStart === null
                                    && newInitialValues.timeRangeEnd === null,
                            });

                            Object.assign(newInitialValues, {
                                timeRangeHourStart: conditions.timeRangeStart ? {
                                    value: extractHourValue(moment(conditions.timeRangeStart)),
                                    label: moment(conditions.timeRangeStart).format('hh')
                                } : null,
                                timeRangeMinuteStart: conditions.timeRangeStart ? {
                                    value: moment(conditions.timeRangeStart).format('mm'),
                                    label: moment(conditions.timeRangeStart).format('mm')
                                } : null,
                                timeRangeTypeStart: conditions.timeRangeStart ? {
                                    value: moment(conditions.timeRangeStart).format('A')[0],
                                    label: moment(conditions.timeRangeStart).format('A')
                                } : null,

                                timeRangeHourEnd: conditions.timeRangeEnd ? {
                                    value: extractHourValue(moment(conditions.timeRangeEnd)),
                                    label: moment(conditions.timeRangeEnd).format('hh')
                                } : null,
                                timeRangeMinuteEnd: conditions.timeRangeEnd ? {
                                    value: moment(conditions.timeRangeEnd).format('mm'),
                                    label: moment(conditions.timeRangeEnd).format('mm')
                                } : null,
                                timeRangeTypeEnd: conditions.timeRangeEnd ? {
                                    value: moment(conditions.timeRangeEnd).format('A')[0],
                                    label: moment(conditions.timeRangeEnd).format('A')
                                } : null,
                            });
                        }
                    } else {
                        Object.assign(newInitialValues, {
                            scheduleDateTime: newInitialValues.schedDate == null ? null : moment(newInitialValues.schedDate),
                        });
                    }
                    handleRadioButtonChange(Number(nudgeDetails.message.tapBehavior));
                    setInitialValues({
                        ...newInitialValues,
                        updateDraft: newInitialValues.isDraft,
                        updateDraftId: newInitialValues.actionId,
                        selectedWeekDaysEveryDay:
                            newInitialValues.selectedWeekDays
                            && newInitialValues.selectedWeekDays.length === WEEKDAY_OPTIONS.length,
                    });
                    fetchLocations(newInitialValues.nudgeType);
                } catch (err) {
                    Analytics.trackApplicationError(err.message, FILE_NAME, 'fetchNudge');
                    notification.alert(err.message, 'danger');
                }
            }
        }

        if (maxFreqOptions.length === 0) {
            fetchOrganizationInfo(organizationId);
        }

        if (isUpdate) {
            fetchNudge();
        } else {
            fetchLocations(urlNudgeType);
        }
    }, [
        organizationId,
        urlNudgeType,
        locations,
        setLocations,
        isUpdate,
        initialValues,
        setInitialValues,
        actionId,
        locationsService,
        notification,
        nudgeService,
        getLocationsQuery,
        getNudgeQuery,
    ]);

    useEffect(async () => {
        const getMessageCategories = async () => {
            try {
                // call for categories
                const nudgeCategoryResponse = await NudgeService.getNudgeAndCampaignCategories(
                    getNudgeMessageCategories,
                );
                setMessageCategoryOptions(nudgeCategoryResponse)
            } catch (e) {
                console.log(e.message)
            }
        }
        await getMessageCategories()
    }, [])

    const formik = useFormik({
        initialValues: {
            environment: ENVIRONMENTS[1],
            nudgeType: urlNudgeType,
            name: '',
            description: '',
            title: '',
            segmentId: null,
            body: '',
            url: '',
            scheduleDateTime: null,
            scheduleTimeHour: {value: 10, label: '10'},
            scheduleTimeMinute: {value: 0, label: '00'},
            scheduleTimeType: TIME_TYPE[0], // AM
            scheduleNow: false,
            selectedLocations: [],
            radius: GEOFENCE_PRESETS[1],
            dateTimeRangeStart: null,
            dateTimeRangeStartNow: false,
            dateTimeRangeEnd: null,
            dateTimeRangeEndNever: false,
            selectedWeekDays: [],
            selectedWeekDaysEveryDay: false,
            timeRangeStart: null,
            timeRangeEnd: null,
            timeRangeHourStart: {value: 10, label: '10'},
            timeRangeMinuteStart: {value: 0, label: '00'},
            timeRangeTypeStart: TIME_TYPE[0], // AM
            timeRangeHourEnd: {value: 10, label: '10'},
            timeRangeMinuteEnd: {value: 0, label: '00'},
            timeRangeTypeEnd: TIME_TYPE[1], // PM
            tapBehavior: 0,
            delay: DWELL_PRESETS[1],
            messageCapLimitOverride: {value: false, label: 'No'},
            messageMaxFreq: nudgeType === NUDGE_TYPES.GEOFENCE.ID || urlNudgeType === NUDGE_TYPES.GEOFENCE.ID ? {value: 10080, label: 'Once a week'} : null,
            urlLinkType: MESSAGE_LINK_OPTIONS[0],
            allDay: false,
            isDraft: true,
            archived: false,
            messageCategory: null,
            ...initialValues,
        },
        validateOnChange: false,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
                try {

                let isPermittedToCreate = true;
                let {url} = values;
                let telephoneUrl = false;
                const valid = url && url.match(/^https?:\/\//);

                if (url) {
                    const telephoneUrlStart = 'tel:';
                    if (url.slice(0, 4) === telephoneUrlStart) {
                        values.url = url;
                        telephoneUrl = true;
                    }
                    if (!valid && !telephoneUrl && values.urlLinkType.value === 'Weblink') {
                        url = `http://${values.url}`;

                        if (!rfc1738UrlRegex.test(url)) {
                            throw new Error('Please enter a valid URL');
                        }

                        values.url = url;
                    }
                }

                // modify invalid user-inputed blank date for nudge drafts
                if (values.nudgeType === NUDGE_TYPES.SCHEDULED_ACTION.ID && values.isDraft) {
                    values.scheduleDateTime = moment(values.scheduleDateTime);

                }


                // Will send only the int value for category to the backend
                const {messageCategory} = values;
                if (messageCategory) {
                    values.messageCategoryId = values.messageCategory.value;
                }

                // display warning before creating nudge if time-based
                if (values.nudgeType === NUDGE_TYPES.SCHEDULED_ACTION.ID && !values.isDraft) {
                    const currentDate = moment(formik.values.scheduleDateTime);

                    const date = currentDate.format('MM/DD/YYYY');
                    const time = currentDate.format('LT');
                    const segmentId = values.segmentId.value;
                    let memberCount;
                    // Will call on creation of a nudge when segmentId = 0, which will call for all segments in that org
                    if (segmentId === 0) {
                        const res = await SegmentService.countAllSegments(getCountAllSegmentsQuery, organizationId, user.id, 'ALL');
                        memberCount = res.count;
                    } else {
                        const segment = await SegmentService.index(getSegmentQuery, values.segmentId.value);
                        memberCount = segment.memberCounts;
                    }
                    isPermittedToCreate = await NudgeService.warningBeforeCreatingNudge(memberCount, date, time);
                }
                if (isPermittedToCreate) {
                    const {segmentId} = values;

                    if (isUpdate) {
                        createOrUpdateNudgeService.send('UPDATE', {
                            updateTimeBasedNudgeMutation,
                            updateGeolocationNudgeMutation,
                            values,
                        });
                    } else {
                        createOrUpdateNudgeService.send('CREATE', {
                            createTimeBasedNudgeMutation,
                            createGeolocationNudgeMutation,
                            values,
                            organizationId,
                        });
                    }
                }
            } catch (err) {
                notification.alert(err.message, 'danger');
            }
        },
    });
    const setIsDraft = async (value) => {
        formik.setFieldValue('isDraft', value);
    };

    const setNudgeType = (key, value) => {
        const args = [{key, value}];
        history.replace(`${location.pathname}${buildQueryParamsURL(args)}`);
        formik.setFieldValue(key, value);
    };

    const handleChangeOptionalValue = (name, value, fieldName, fieldValue) => {
        formik.setFieldValue(name, value);
        formik.setFieldValue(fieldName, value ? fieldValue : null);
    };

    const handleDateTimeRange = (name, date, format) => {
        let parsedDate = date;
        if (typeof date === 'string') {
            parsedDate = moment(date, format);
        }
        // we only convert the string
        if (parsedDate instanceof Date) formik.setFieldValue(name, parsedDate.toDate());
    };

    const handleTimeBasedNudge = async () => {
        /*
        *  Here is were we take care of creating/updating time-based nudges.
         */
        // if ' Schedule Nudge for right now' is checked we ignored whatever
        // data is in the date and time dropdowns
        if (formik.values.scheduleNow) {
            await formik.setFieldValue('scheduleDateTime', moment());
        } else if (formik.values.scheduleTimeHour && formik.values.scheduleTimeMinute
            && formik.values.scheduleTimeType) {
            // insert time values from drop down into main dateTime object
            const oldTime = nudgeDetails
                ? getDateAndTimeFromConditions(nudgeDetails.conditions).time : null;
            await formik.setFieldValue('scheduleDateTime', getDateTimeObjectWithUpdate(
                isUpdate, oldTime, formik.values.scheduleTimeType.label,
                formik.values.scheduleTimeType.value, formik.values.scheduleDateTime,
                formik.values.scheduleTimeHour.value, formik.values.scheduleTimeMinute.value,
            ));
        }
    };

    const handleGeoNudge = async () => {
        /*
        *  Here is where we take care of creating/updating time-based nudges.
        *  this function assumes that NudgeType = NUDGE_TYPES.GEOFENCE.ID
         */
        if (formik.values.dateTimeRangeStart) {
            await formik.setFieldValue('dateTimeRangeStart', moment(formik.values.dateTimeRangeStart));
        }
        if (formik.values.dateTimeRangeEnd) {
            await formik.setFieldValue('dateTimeRangeEnd', moment(formik.values.dateTimeRangeEnd));
        }
        // Geo nudge active window time
        // insert time values from drop down into main dateTime object, Starting Geo Nudge time
        if (!formik.values.allDay && formik.values.timeRangeHourStart
            && formik.values.timeRangeMinuteStart && formik.values.timeRangeTypeStart) {

            await formik.setFieldValue('timeRangeStart', getDateTimeObject(
                formik.values.timeRangeTypeStart.value, new Date(),
                formik.values.timeRangeHourStart.value, formik.values.timeRangeMinuteStart.value,
            ));

            await handleDateTimeRange('timeRangeStart', formik.values.timeRangeStart, INPUT_TIME_FORMAT);
        }
        // insert time values from drop down into main dateTime object, Ending Geo Nudge time
        if (!formik.values.allDay && formik.values.timeRangeHourEnd && formik.values.timeRangeMinuteEnd
            && formik.values.timeRangeTypeEnd) {

            await formik.setFieldValue('timeRangeEnd', getDateTimeObject(
                formik.values.timeRangeTypeEnd.value, new Date(),
                formik.values.timeRangeHourEnd.value, formik.values.timeRangeMinuteEnd.value,
            ));

            await handleDateTimeRange('timeRangeEnd', formik.values.timeRangeEnd, INPUT_TIME_FORMAT);
        }
    };

    const handleSubmitForm = async (isDraft) => {
        // Formik tip, helpful for debugging
        // validationSchema().validate(formik.values)
        // .catch(function(e) {
        //     console.log(e);
        // });
        if (isDisabled()) {
            history.push(ROUTES.NUDGES.MAIN);
        } else {
            const formNudgeType = formik.values.nudgeType;

            if ((formik.values.scheduleDateTime || formik.values.scheduleNow)
                && formNudgeType === NUDGE_TYPES.SCHEDULED_ACTION.ID) {
                await handleTimeBasedNudge();
            }

            // geo based nudge time edit start
            if (formNudgeType === NUDGE_TYPES.GEOFENCE.ID) {
                await handleGeoNudge();
            }

            // formik.setFieldValue('isDraft', isDraft);
            /* formik.setFieldValue is async and we need the value to change
            before perform validation, so we wrap formik.submitForm inside a timeout */
            setTimeout(formik.submitForm);
        }
    };

    const isSelectedWeekDaysEveryDay = formik.values.selectedWeekDays
        && formik.values.selectedWeekDays.length === WEEKDAY_OPTIONS.length;

    const isTimeRangeAllDay = formik.values.timeRangeStart
        && moment(formik.values.timeRangeStart).format('HH:mm') === '00:00'
        && formik.values.timeRangeEnd
        && moment(formik.values.timeRangeEnd).format('HH:mm') === '23:59';

    if (!formik.isValid && !formik.isSubmitting) {
        notification.alert(
            'Some fields have errors, please correct them',
            'warning',
        );
    }

    const userIsViewOnly = () => !userHasTimeNudgesEdit && !userHasGeoNudgesEdit

    const userIsDeeplink = () => userHasDeepLink

    const isDisabled = (disabledOverride) => {
        const {conditions, sent, archived} = initialValues;
        if (!userHasTimeNudgesEdit && !userHasGeoNudgesEdit) {
            return true;
        }
        if (archived) {
            return true;
        }
        if (initialValues.isDraft && !userHasTimeNudgesEdit) {
            return true;
        }
        if (initialValues.isDraft && !userHasGeoNudgesEdit && initialValues.nudgeType === NUDGE_TYPES.GEOFENCE.ID) {
            return true;
        }
        if (initialValues.nudgeType === NUDGE_TYPES.SCHEDULED_ACTION.ID) {
            if (!userHasTimeNudgesEdit) {
                return true;
            }
            return sent || isTimeBasedNudgeExpired(conditions);
        }
        if (initialValues.nudgeType === NUDGE_TYPES.GEOFENCE.ID) {
            if (!userHasGeoNudgesEdit) {
                return true;
            }
            return isLocationNudgeExpired(conditions);
        }
        if (sent) {
            return sent;
        }
        return disabledOverride;
    };

    const disableIsDeeplink = (disableddeeplink) => {
        if (!userIsDeeplink()) {
            return true
        }
        if (isDisabled()) {
            return true
        }
        return disableddeeplink;
        }



    if (isUpdate && Object.keys(initialValues).length === 0) {
        return (
            <InitialLoader/>
        );
    }

    const getTitle = () => {
        const {isDraft} = initialValues;
        if (isDisabled()) {
            return 'View this nudge';
        }
        if (isUpdate) {
            if (isDraft) {
                return 'Edit this draft nudge';
            }
            return 'Edit this nudge';
        }
        return 'Create a nudge';
    };

    const setSubmitButtonText = () => {
        if (isDisabled()) {
            return 'Close';
        }
        if (!isUpdate || (isUpdate && initialValues.isDraft)) {
            return 'Create this nudge';
        }
        return 'Update this nudge';
    };
    const showAnalyticsPopup = () => {
        setShowModal(true);
    };
    const hideModalCallback = () => {
        setShowModal(false);
    };
    const currentNudgeMsg = currentNudge ? currentNudge.message : null;
    const getAnalyticsModalParams = () => {
        const downloadAnalyticsParams = {
            ENVIRONMENT,
            FILE_NAME,
            organizationId,
            notification,
            getNudgesReceivedQuery,
            getNudgesTappedQuery,
            getNudgesHeadersQuery,
            NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD,
        };
        if (currentNudgeMsg) {
            return {
                id: currentNudgeMsg.id ? currentNudgeMsg.id : '',
                name: currentNudgeMsg.name,
                analyticsParams: downloadAnalyticsParams,
                type: 'Nudge',
            };
        }
        return {id: '', name: ''};
    };

    const nudgeAssistPopup = () => {
        if (!userNudgeAssist) {
              window.open(NudgeAssistUrl, '_blank');
        }
        else {
            setNudgeAssistShowModal(true);
            setModalKey((prevKey) => prevKey + 1);
        }
    };

    const hideNudgeAssistModalCallback = () => {
        setNudgeAssistShowModal(false);
    };

    const handleOk = async (values) => {
    formik.setValues({
      ...formik.values,
      title: values.option1,
      body: values.option2,
    });
    setShowModal(false);

    // This will call when initially creating a nudge to set up nudge ML score info
    const nudgeScoreResponse = await NudgeService.getNudgeScore(
         getNudgeScoreQuery,
                values.option2 || ' ',
                values.option1 || ' '
      );
    setNudgeScoreData(nudgeScoreResponse)
    setNudgeScoreCalculating(false)
    return null;
  };

  const recheckscore = async (values) => {
    setNudgeScoreCalculating(true);
    //This will call on updating of the body/title fields to set up nudge ML score info
    if (formik.values.body || formik.values.title) {
        const nudgeScoreResponse = await NudgeService.getNudgeScore(
                    getNudgeScoreQuery,
                    formik.values.body || ' ',
                    formik.values.title || ' '
                );
        setNudgeScoreData(nudgeScoreResponse)
        setNudgeScoreCalculating(false)
        setNudgeAssist(false);
        return null;
    }

    //This will call on loading of the page to set up nudge ML score info
    if (initialValues.body || initialValues.title) {
       const nudgeScoreResponse = await NudgeService.getNudgeScore(
                    getNudgeScoreQuery,
                    initialValues.body || ' ',
                    initialValues.title || ' '
       );
       setNudgeScoreData(nudgeScoreResponse)
       setNudgeScoreCalculating(false)
       return null;
    }

    const nudgeScoreResponse = await NudgeService.getNudgeScore(
                    getNudgeScoreQuery,
                    formik.values.body || ' ',
                    formik.values.title || ' '
                );
        setNudgeScoreData(nudgeScoreResponse)
        setNudgeScoreCalculating(false)
  };

    const handleFormikAddEmoji = async (valueToChange, newValue) => {
        const newVal = formik.values[valueToChange].concat(newValue);
        await formik.setFieldValue(valueToChange, newVal);

    };

    const handleCancelCreateOrEditNudge = async () => {
        history.push(ROUTES.NUDGES.MAIN);
    };

        const radioButtonsInput = () => radioOptions.map((option) => (
            <CustomRadioButton
                key={option.value}
                name="nudgeTapBehavior"
                value={option.value}
                onChange={(value) => handleRadioButtonChange(value)}
                option={option}
                disabled={isDisabled()}
            />
        ));

    //     {formik.isSubmitting && (
    //   <div className={styles.spinnerWrapper}>
    //     <Spinner
    //       as="span"
    //       animation="border"
    //       size="lg"
    //       role="status"
    //       aria-hidden="true"
    //     />
    //   </div>
    // )}

  //   if (formik.isSubmitting) {
  //   return (
  //       <div className={styles.root} style={{margin: '40rem'}}>
  //       <Spinner style={{width:'6rem',height: '6rem'}}
  //         as="span"
  //         animation="border"
  //         size="xl"
  //         role="status"
  //         aria-hidden="true"
  //       />
  //     </div>
  //   );
  // }


    return (
    <div className={styles.root}>
        {formik.isSubmitting && (
            <div className={styles.overlay}>
                <div className={styles.messageContainer}>
                    <p className={styles.message}>One moment please: <br />Processing your nudge…</p>
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                        className={styles.spinner}
                    />
                </div>
            </div>

        )}
            <form onSubmit={formik.handleSubmit} className={formik.isSubmitting ? styles.disabledForm : ''}>
                <Row className={styles['nudge-component']}>
                    <Col style={{flex: 7}} className={styles['nudge-component-1']}>
                        <Row className={styles.section}>
                            <CustomSection header>
                                <PageHeader
                                    title={getTitle()}
                                    subtitle="Create the nudge that members will receive at the right time"
                                />
                            </CustomSection>
                        </Row>

                        {/* NUDGE TYPE */}
                        <Row className={styles.section}>
                            <CustomSection title="Select nudge type">
                                <Row style={{margin: 0}}>
                                    <Col xs={12} md="auto" style={{padding: 0}}>
                                        <CustomButton
                                            autoMargin
                                            variant={
                                                formik.values.nudgeType === NUDGE_TYPES.SCHEDULED_ACTION.ID ? 'contained' : 'outlined'
                                            }
                                            size="lg"
                                            text="Time-Based"
                                            onClick={() => setNudgeType('nudgeType', NUDGE_TYPES.SCHEDULED_ACTION.ID)}
                                            disabled={isUpdate}
                                            className={isUpdate === true ? styles['disable-type-button'] : ''}
                                        />
                                    </Col>
                                    <Col className={styles.pl} xs={12} md="auto" style={{padding: 0}}>
                                        <CustomButton
                                            autoMargin
                                            variant={
                                                formik.values.nudgeType === NUDGE_TYPES.GEOFENCE.ID ? 'contained' : 'outlined'
                                            }
                                            toolTipText={!geoLocationFlag ? 'Your implementation doesn’t include location-based nudges. Please contact support@larky.com for more information.' : null}
                                            size="lg"
                                            text="Geolocation"
                                            onClick={() => setNudgeType('nudgeType', NUDGE_TYPES.GEOFENCE.ID)}
                                            disabled={isUpdate || !geoLocationFlag}
                                            className={isUpdate || !geoLocationFlag ? styles['disable-type-button'] : ''}
                                        />
                                    </Col>
                                </Row>
                                <div className={styles.horizontal}/>
                            </CustomSection>
                        </Row>

                        {/* BASIC NUDGE INFORMATION */}
                        <Row className={styles.section}>
                            <CustomSection title="Organize your nudge">
                                <h6 style={{marginLeft: 3}}>None of this will be visible to your recipients</h6>
                                <CustomInput
                                    type="text"
                                    name="name"
                                    title="Name your nudge"
                                    placeholder="e.g. Auto loan promo"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.errors.name}
                                    disabled={isDisabled()}
                                />
                                <CustomInput
                                    type="textarea"
                                    name="description"
                                    title="Give your nudge a description"
                                    placeholder="e.g. Promote low interest rates"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={formik.errors.description}
                                    disabled={isDisabled()}
                                />
                                <CustomInput
                                    type="select"
                                    name="selectCategory"
                                    title="Select a category for your nudge"
                                    description="Categories help organize your nudges and give a big picture view for analytics"
                                    placeholder="Select..."
                                    onChange={(messageCategory) => {
                                        formik.setFieldValue('messageCategory', messageCategory)
                                    }}
                                    id='select-category-dropdown'
                                    value={formik.values.messageCategory}
                                    error={formik.errors.messageCategory}
                                    options={messageCategoryOptions}
                                    disabled={isDisabled()}
                                />
                                </CustomSection>
                            </Row>
                            <Row className={styles.section}>
    <CustomSection title="Write your nudge">
        {!isDisabled(false) && !userIsViewOnly() && (
            <div>
                <Larky.Button
                    data-tip={!userNudgeAssist ? createButtonToolTipText() : 'Use nudge Assist for help composing your nudge!'}
                    className={!userNudgeAssist ? styles['disable-type-button'] : null}
                    onClick={nudgeAssistPopup}
                >
                    <img src={ButtonImage} alt="Button Image" style={{ marginBottom: '11px' }} />
                    nudge Assist
                </Larky.Button>
                <ReactTooltip />
            </div>
        )}

        <CustomInput
            type="text"
            name="title"
            title="Give your nudge a title"
            placeholder="e.g. Don’t let these great rates pass you by!"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.errors.title}
            disabled={isDisabled()}
            showEmoji
            emojiCallback={handleFormikAddEmoji}
            fieldToEdit="title"
            inModal={false}
            setIsFocusedCallback={(boolVal) => {
                const isInFocus = boolVal ? 'in' : 'out';
                setTitleInputInFocus(isInFocus);
            }}
        />

        <CustomInput
            type="textarea"
            name="body"
            title="Write your nudge message"
            placeholder="e.g. Finance your next new or used car with our low interest rates! Tap to learn more"
            value={formik.values.body}
            onChange={formik.handleChange}
            error={formik.errors.body}
            disabled={isDisabled()}
            showEmoji
            emojiCallback={handleFormikAddEmoji}
            fieldToEdit="body"
            inModal={false}
            setIsFocusedCallback={(boolVal) => {
                const isInFocus = boolVal ? 'in' : 'out';
                setMessageInputInFocus(isInFocus);
            }}
        />

        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <div style={{ flexGrow: 1 }}>
            <CustomInput
                type="text"
                name="url"
                title="Provide the destination for when the nudge is tapped"
                description=""
                placeholder="e.g. mainstreetsavings.com or https://mainstreetsavings.com/loans?utm_source=nudge"
                value={formik.values.url}
                onChange={formik.handleChange}
                error={formik.errors.url}
                disabled={isDisabled()}
            />
        </div>

        <div style={{ flexBasis: '20%', alignSelf: 'flex-end' }}>
            <CustomInput
            type="select"
            id="urlLinkType"
            name="urlLinkType"
            title=""
            onChange={(type) => formik.setFieldValue('urlLinkType', type)}
            // onChange={(value) => formik.setFieldValue('urlLinkType', value)}
            value={formik.values.urlLinkType}
            disabled={disableIsDeeplink()}
            error={formik.errors.urlLinkType}
            options={MESSAGE_LINK_OPTIONS}
            align={formik.errors.url ? 1: null}
        />

        </div>
    </div>

    </CustomSection>
</Row>

                        {/* SEGMENT INFORMATION */}
                        <Row className={styles.section}>
                            <CustomSection title="Select the recipients for your nudge">
                                <CustomInput
                                    type="select"
                                    name="selectSegment"
                                    title="Select all possible recipients, or specify a segment of recipients"
                                    placeholder="Select..."
                                    onChange={(segment) => formik.setFieldValue('segmentId', segment)}
                                    value={formik.values.segmentId}
                                    error={formik.errors.segmentId}
                                    options={[
                                    { label: '', options: [ALL_POSSIBLE_RECIPIENTS_OPTION] },
                                    ...(userHashubSpotSegmentationPermission && hubspotSegmentList.length > 0
                                        ? [{ label: 'LARKY CONDUIT SOURCE: HUBSPOT', options: hubspotSegmentList }]
                                        : []),
                                    ...(userHasTrellanceSegmentationPermission && trellanceSegmentList.length > 0
                                        ? [{ label: 'LARKY CONDUIT SOURCE: TRELLANCE', options: trellanceSegmentList }]
                                        : []),
                                    { label: 'Segments', options: userSegmentList }
                                    ]}
                                    disabled={isDisabled()}
                                />
                                {formik.values.nudgeType === NUDGE_TYPES.GEOFENCE.ID && (
                                    <div>
                                        <h5 style={{font: 'bold 25px/24px Arial MT, Arial'}}>
                                            Choose what happens when a recipient taps a nudge
                                        </h5>
                                        {radioButtonsInput()}
                                    </div>
                                )}
                            </CustomSection>
                        </Row>
                        {/* TIME-BASED */}
                        {formik.values.nudgeType === NUDGE_TYPES.SCHEDULED_ACTION.ID && (
                            <Row className={styles.section}>

                                <CustomSection title="Schedule your nudge">
                                    <Row>
                                        <h4 className={styles['form-question-header']} style={{marginLeft: 10}}>
                                            Set the date and time when your nudge should begin sending
                                        </h4>

                                        <div className={styles.horizontal} style={{flexWrap: 'wrap'}}>

                                            <TimeLabel title="" style={{marginRight: 2}}>
                                                <CustomInput
                                                    type="datetime"
                                                    name="scheduleDateTime"
                                                    title=""
                                                    placeholder="Select..."
                                                    dateFormat={INPUT_DATE_FORMAT}
                                                    onChange={(date) => {
                                                        formik.setFieldValue('scheduleDateTime', date);
                                                    }}
                                                    value={formik.values.scheduleDateTime}
                                                    disabled={isDisabled() || formik.values.scheduleNow}
                                                    error={formik.errors.scheduleDateTime}
                                                />

                                            </TimeLabel>

                                            <TimeLabel title="" style={{marginRight: 1}}>
                                                <CustomInput
                                                    type="select"
                                                    name="scheduleTimeHour"
                                                    title=""
                                                    placeholder="10"
                                                    onChange={(hour) => {
                                                        formik.setFieldValue('scheduleTimeHour', hour);
                                                    }}
                                                    value={formik.values.scheduleTimeHour}
                                                    disabled={isDisabled() || formik.values.scheduleNow}
                                                    error={formik.errors.scheduleTimeHour}
                                                    options={TIME_HOUR}
                                                />
                                                <CustomInput
                                                    type="select"
                                                    name="scheduleTimeMinute"
                                                    title=""
                                                    placeholder="00"
                                                    onChange={(minute) => formik.setFieldValue('scheduleTimeMinute', minute)}
                                                    value={formik.values.scheduleTimeMinute}
                                                    disabled={isDisabled() || formik.values.scheduleNow}
                                                    error={formik.errors.scheduleTimeMinute}
                                                    options={TIME_MINUTE}
                                                />
                                                <CustomInput
                                                    type="select"
                                                    name="scheduleTimeType"
                                                    title=""
                                                    placeholder={TIME_TYPE[0].label}
                                                    onChange={(type) => formik.setFieldValue('scheduleTimeType', type)}
                                                    value={formik.values.scheduleTimeType}
                                                    disabled={isDisabled() || formik.values.scheduleNow}
                                                    error={formik.errors.scheduleTimeType}
                                                    options={TIME_TYPE}
                                                />

                                            </TimeLabel>
                                                <div style={{marginRight: 0, paddingTop: 6}}>&nbsp;&nbsp;{TIME_ZONE}
                                                </div>

                                        </div>
                                    </Row>
                                     <div className={styles.container}>
                                        <div className={styles.checkboxWrapper}>
                                            <CustomCheckbox
                                                name="scheduleNow"
                                                value={formik.values.scheduleNow}
                                                onChange={(value) => handleChangeOptionalValue(
                                                    'scheduleNow',
                                                    value,
                                                    'scheduleDateTime',
                                                    null,
                                                )}
                                                size={20}
                                                disabled={isDisabled()}
                                            />
                                            <div className={styles.checkboxLabel}>
                                                Schedule Nudge for right now
                                            </div>
                                        </div>
                                    </div>


                                    <br></br>



                                    <Row>
                                        <h4 className={styles['form-question-header']} style={{marginLeft: 10}}>
                                            Override message capping limits?
                                        </h4>
                                    </Row>
                                    <Row>
                                        <h6 style={{marginLeft: 10}}>
                                            If this is a critical message that should always be delivered, choose Yes
                                        </h6>
                                    </Row>
                                    <Row style={{marginRight: 10, display: 'flex'}}>
                                        <Col style={{flex: 1}}>

                                            <CustomInput
                                                type="select"
                                                name="messageCapLimitOverride"
                                                title=""
                                                // placeholder="AM"
                                                onChange={(value) => formik.setFieldValue('messageCapLimitOverride', value)}
                                                value={formik.values.messageCapLimitOverride}
                                                disabled={isDisabled()}
                                                error={formik.errors.messageCapLimitOverride}
                                                options={BYPASS_LIMIT_OPTIONS}
                                            />
                                        </Col>
                                        <Col style={{flex: 6}}/>
                                    </Row>
                                </CustomSection>
                            </Row>
                        )}
                        {/* GEOLOCATION */}
                        {formik.values.nudgeType === NUDGE_TYPES.GEOFENCE.ID && (
                            <>
                                {/* LOCATIONS */}
                                <Row className={styles.section}>
                                    <CustomSection title="Choose your location(s)">
                                        <CustomInput
                                            type="select"
                                            name="selectedLocations"
                                            title="Select one or more locations"
                                            placeholder="Select..."
                                            options={locations}
                                            onChange={(values) => formik.setFieldValue('selectedLocations', values)}
                                            value={formik.values.selectedLocations}
                                            error={formik.errors.selectedLocations}
                                            disabled={isDisabled()}
                                            isMulti
                                        />
                                        {/* Temporarily commented out pending getlarky/nudge#894

                    <CustomInput title="Don't see the location you want?">
                      <CustomButton
                        variant="contained"
                        size="lg"
                        text="Add a location"
                        onClick={() => history.push('/locations/create')}
                        disabled={isDisabled()}
                      />
                    </CustomInput> */}
                                        <CustomInput
                                            type="select"
                                            name="radius"
                                            title="Choose how close users should be to the location(s)"
                                            placeholder="About a 1 block radius..."
                                            options={GEOFENCE_PRESETS}
                                            onChange={(value) => formik.setFieldValue('radius', value)}
                                            value={formik.values.radius}
                                            error={formik.errors.radius}
                                            disabled={isDisabled()}
                                        />

                                        <CustomInput
                                            type="select"
                                            name="delay"
                                            title="How long should recipient be at location before nudge is delivered?"
                                            placeholder="Deliver immediately"
                                            options={dwellOptions}
                                            onChange={(value) => formik.setFieldValue('delay', value)}
                                            value={formik.values.delay}
                                            error={formik.errors.delay}
                                            disabled={isDisabled()}
                                        />
                                    </CustomSection>
                                </Row>

                                {/* START-STOP TIMES */}
                                <Row className={styles.section}>
                                    <CustomSection title="Set your start and stop times">
                                        <CustomInput title="Choose when your nudge is active">
                                            <TimeLabel title="Starting:"
                                                       style={{flexWrap: 'wrap', justifyContent: 'start'}}>
                                                <Row style={{
                                                    alignItems: 'center', margin: 0, marginRight: 10, display: 'flex',
                                                }}
                                                >
                                                    <TimeLabel title="" style={{marginRight: 1}}>
                                                        <CustomInput
                                                            type="datetime"
                                                            name="dateTimeRangeStart"
                                                            placeholder="Select..."
                                                            dateFormat={INPUT_DATE_FORMAT}
                                                            onChange={(date) => {
                                                                formik.setFieldValue('dateTimeRangeStart', date);
                                                            }}
                                                            value={moment(formik.values.dateTimeRangeStart)}
                                                            error={formik.errors.dateTimeRangeStart}
                                                            disabled={isDisabled() || formik.values.dateTimeRangeStartNow}
                                                        />
                                                    </TimeLabel>

                                                    <Col md="auto">
                                                        <SelectableFilter
                                                            className={styles.label}
                                                            label="Today"
                                                            name="dateTimeRangeStartNow"
                                                            value={Boolean(formik.values.dateTimeRangeStartNow)}
                                                            onChange={(value) => {
                                                                formik.setFieldValue('dateTimeRangeStart', null);
                                                                formik.setFieldValue('dateTimeRangeStartNow', value);
                                                            }}
                                                            disabled={isDisabled()}
                                                        />
                                                    </Col>
                                                </Row>
                                            </TimeLabel>
                                            <TimeLabel title="Ending:"
                                                       style={{flexWrap: 'wrap', justifyContent: 'start'}}>
                                                <Row style={{
                                                    alignItems: 'center',
                                                    margin: 0,
                                                    marginRight: 10,
                                                    marginLeft: 6,
                                                    display: 'flex',
                                                }}
                                                >
                                                    <TimeLabel title="" style={{marginRight: 1}}>
                                                        <CustomInput
                                                            type="datetime"
                                                            name="dateTimeRangeEnd"
                                                            placeholder="Select..."
                                                            dateFormat={INPUT_DATE_FORMAT}
                                                            onChange={(date) => {
                                                                formik.setFieldValue('dateTimeRangeEnd', date);
                                                            }}
                                                            value={moment(formik.values.dateTimeRangeEnd)}
                                                            error={formik.errors.dateTimeRangeEnd}
                                                            disabled={isDisabled() || formik.values.dateTimeRangeEndNever}
                                                        />
                                                    </TimeLabel>
                                                    <Col md="auto">
                                                        <SelectableFilter
                                                            className={styles.label}
                                                            label="Never"
                                                            name="dateTimeRangeEndNever"
                                                            value={Boolean(formik.values.dateTimeRangeEndNever)}
                                                            onChange={(value) => {
                                                                formik.setFieldValue('dateTimeRangeEnd', null);
                                                                formik.setFieldValue(
                                                                    'dateTimeRangeEndNever',
                                                                    value,
                                                                );
                                                            }}
                                                            disabled={isDisabled()}
                                                        />
                                                    </Col>
                                                </Row>

                                            </TimeLabel>
                                        </CustomInput>
                                        <div className={styles['week-days-wrapper']} style={{flexWrap: 'wrap'}}>
                                            <CustomInput
                                                type="select"
                                                name="selectedWeekDays"
                                                title="Add active window(s)"
                                                placeholder="Every day"
                                                options={WEEKDAY_OPTIONS}
                                                onChange={(values) => formik.setFieldValue('selectedWeekDays', values)}
                                                value={formik.values.selectedWeekDays}
                                                error={formik.errors.selectedWeekDays}
                                                disabled={isDisabled()}
                                                isMulti
                                                style={{flex: 1}}
                                            />
                                            <SelectableFilter
                                                className={styles.label}
                                                label="Every day"
                                                name="selectedWeekDaysEveryDay"
                                                value={Boolean(
                                                    formik.values.selectedWeekDaysEveryDay
                                                    || isSelectedWeekDaysEveryDay,
                                                )}
                                                onChange={(value) => handleChangeOptionalValue(
                                                    'selectedWeekDaysEveryDay',
                                                    value,
                                                    'selectedWeekDays',
                                                    WEEKDAY_OPTIONS,
                                                )}
                                                disabled={isDisabled()}
                                            />
                                        </div>
                                          <div>
                                            <div className={styles.horizontal}>
                                                <div className={styles.timeLabelContainer}>
                                                    <TimeLabel title="Starting:" className={styles.timeLabel}>
                                                        <CustomInput
                                                            type="select"
                                                            name="timeRangeHourStart"
                                                            title=""
                                                            placeholder="12"
                                                            onChange={(hour) => formik.setFieldValue('timeRangeHourStart', hour)}
                                                            value={formik.values.timeRangeHourStart}
                                                            disabled={isDisabled() || formik.values.allDay || isTimeRangeAllDay}
                                                            error={formik.errors.timeRangeHourStart}
                                                            options={TIME_HOUR}
                                                        />
                                                        <CustomInput
                                                            type="select"
                                                            name="timeRangeMinuteStart"
                                                            title=""
                                                            placeholder="00"
                                                            onChange={(minute) => formik.setFieldValue('timeRangeMinuteStart', minute)}
                                                            value={formik.values.timeRangeMinuteStart}
                                                            disabled={isDisabled() || formik.values.allDay || isTimeRangeAllDay}
                                                            error={formik.errors.timeRangeMinuteStart}
                                                            options={TIME_MINUTE}
                                                        />
                                                        <CustomInput
                                                            type="select"
                                                            name="timeRangeTypeStart"
                                                            title=""
                                                            placeholder="AM"
                                                            onChange={(type) => formik.setFieldValue('timeRangeTypeStart', type)}
                                                            value={formik.values.timeRangeTypeStart}
                                                            disabled={isDisabled() || formik.values.allDay || isTimeRangeAllDay}
                                                            error={formik.errors.timeRangeTypeStart}
                                                            options={TIME_TYPE}
                                                        />
                                                    </TimeLabel>
                                                    <div className={styles.timeZone}>
                                                        {TIME_ZONE}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.horizontal}>
                                                <div className={styles.timeLabelContainer}>
                                                    <TimeLabel title="Ending:" className={styles.timeLabel}>
                                                        <div style={{marginLeft:7,width:'100%'}}><CustomInput
                                                            type="select"
                                                            name="timeRangeHourEnd"
                                                            title=""
                                                            placeholder="12"
                                                            onChange={(hour) => formik.setFieldValue('timeRangeHourEnd', hour)}
                                                            value={formik.values.timeRangeHourEnd}
                                                            disabled={isDisabled() || formik.values.allDay || isTimeRangeAllDay}
                                                            error={formik.errors.timeRangeHourEnd}
                                                            options={TIME_HOUR}
                                                        /></div>

                                                        <CustomInput
                                                            type="select"
                                                            name="timeRangeMinuteEnd"
                                                            title=""
                                                            placeholder="00"
                                                            onChange={(minute) => formik.setFieldValue('timeRangeMinuteEnd', minute)}
                                                            value={formik.values.timeRangeMinuteEnd}
                                                            disabled={isDisabled() || formik.values.allDay || isTimeRangeAllDay}
                                                            error={formik.errors.timeRangeMinuteEnd}
                                                            options={TIME_MINUTE}
                                                        />
                                                        <CustomInput
                                                            type="select"
                                                            name="timeRangeTypeEnd"
                                                            title=""
                                                            placeholder="AM"
                                                            onChange={(type) => formik.setFieldValue('timeRangeTypeEnd', type)}
                                                            value={formik.values.timeRangeTypeEnd}
                                                            disabled={isDisabled() || formik.values.allDay || isTimeRangeAllDay}
                                                            error={formik.errors.timeRangeTypeEnd}
                                                            options={TIME_TYPE}
                                                        />
                                                    </TimeLabel>
                                                    <div className={styles.timeZone}>
                                                        {TIME_ZONE}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{marginTop: -20,marginLeft:103,width:'100%'}}>
                                        <SelectableFilter
                                                label="All day"
                                                name="allDay"
                                                value={Boolean(
                                                    formik.values.allDay || isTimeRangeAllDay,
                                                )}
                                                onChange={(value) => {
                                                    formik.setFieldValue('timeRangeStart', null);
                                                    formik.setFieldValue('timeRangeEnd', null);

                                                    formik.setFieldValue('allDay', value);
                                                }}
                                                disabled={isDisabled()}
                                            />
                                        </div>
                                        <Row>
                                            <h4 className={styles['form-question-header']} style={{marginLeft: 10,marginTop:10}}>
                                                Select maximum frequency
                                            </h4>
                                        </Row>
                                        <Row>
                                            <h6 style={{marginLeft: 10}}>
                                                How often should this nudge be triggered for repeat visits to the target
                                                location?
                                            </h6>
                                        </Row>
                                        <Row style={{marginRight: 10, display: 'flex'}}>
                                            <Col style={{flex: 5}}>
                                                <CustomInput
                                                    type="select"
                                                    name="messageMaxFreq"
                                                    title=""
                                                    onChange={(value) => formik.setFieldValue('messageMaxFreq', value)}
                                                    value={formik.values.messageMaxFreq}
                                                    disabled={isDisabled()}
                                                    error={formik.errors.messageMaxFreq}
                                                    // disables testing option for max frequency if not a testing organization
                                                    options={maxFreqOptions}
                                                />
                                            </Col>
                                            <Col style={{flex: 3}}/>
                                        </Row>

                                        <Row>
                                            <h4 className={styles['form-question-header']} style={{marginLeft: 10,marginTop: 10}}>
                                                Override message capping limits?
                                            </h4>
                                        </Row>
                                        <Row>
                                            <h6 style={{marginLeft: 10}}>
                                                If this is a critical message that should always be delivered, choose
                                                Yes
                                            </h6>
                                        </Row>
                                        <Row style={{marginRight: 10, display: 'flex'}}>
                                            <Col style={{flex: 1}}>
                                                <CustomInput
                                                    type="select"
                                                    name="messageCapLimitOverride"
                                                    title=""
                                                    // placeholder="AM"
                                                    onChange={(value) => formik.setFieldValue('messageCapLimitOverride', value)}
                                                    value={formik.values.messageCapLimitOverride}
                                                    disabled={isDisabled()}
                                                    error={formik.errors.messageCapLimitOverride}
                                                    options={BYPASS_LIMIT_OPTIONS}
                                                />
                                            </Col>
                                            <Col style={{flex: 6}}/>
                                        </Row>

                                    </CustomSection>
                                </Row>
                            </>
                        )}

                        {/* SAVE BUTTONS */}
                        <Row className={styles.horizontal} style={{justifyContent: 'center'}}>
                            <Col xs={12} md="auto">
                                {
                                    ((userHasGeoNudgesEdit || userHasTimeNudgesEdit) && (!isUpdate || initialValues.isDraft))
                                    && (
                                        <CustomButton
                                            variant="outlined"
                                            size="lg"
                                            text={`${isUpdate ? 'Update this draft' : 'Save as draft'}`}
                                            // onClick={() => handleSubmitForm(true)}
                                            onClick={() => {
                                                setIsDraft(true);
                                                handleSubmitForm(true);
                                            }}
                                            toolTipText={(!geoLocationFlag && nudgeType === 1) ? 'Your implementation doesn’t include location-based nudges. Please contact support@larky.com for more information.' : null}
                                            disabled={isDisabled() || (!geoLocationFlag && nudgeType === 1)}
                                            className={isDisabled() || (!geoLocationFlag && nudgeType === 1) ? styles['disable-type-button'] : ''}
                                        />
                                    )
                                }
                            </Col>
                            {
                                (userHasGeoNudgesEdit || userHasTimeNudgesEdit) && (
                                    <Col xs={12} md="auto">
                                        <CustomButton
                                            variant="contained"
                                            size="lg"
                                            text={setSubmitButtonText()}
                                            onClick={() => {
                                                setIsDraft(false);
                                                handleSubmitForm(false);
                                            }}
                                            toolTipText={(!geoLocationFlag && nudgeType === 1) ? 'Your implementation doesn’t include location-based nudges. Please contact support@larky.com for more information.' : null}
                                            disabled={formik.isSubmitting || (!geoLocationFlag && nudgeType === 1)}
                                            className={formik.isSubmitting || (!geoLocationFlag && nudgeType === 1) ? styles['disable-type-button'] : ''}
                                            isLoading={formik.isSubmitting}
                                        />
                                    </Col>
                                )
                            }
                            {
                                (!userHasGeoNudgesEdit && !userHasTimeNudgesEdit) && (
                                    <Col xs={12} md="auto">
                                        <CustomButton
                                            variant="outlined"
                                            size="lg"
                                            text={"Close"}
                                            onClick={() => handleCancelCreateOrEditNudge()}
                                        />
                                    </Col>
                                )
                            }
                        </Row>
                    </Col>

                    {/* NOTIFICATION PREVIEW */}
                    <Col className={`${styles.floating} ${styles['nudge-component-2']}`} style={{flex: 3}}>
                        <CustomSection className={styles['custom-card']}>
                            <div>
                                {(isUpdate && !initialValues.isDraft) ? (
                                    <NudgeAnalyticsData
                                        nudgeAnalyticsData={nudgeAnalyticsData}
                                        loading={nudgeAnalyticsDataLoading}
                                        error={nudgeAnalyticsDataError}
                                    />
                                ) : null}
                                {isUpdate && !initialValues.isDraft && userHasExportAnalytics && nudgeType !== 1 && (
                                    <>
                                        <div className={styles['center-button-row']}>
                                            <Larky.Button
                                                onClick={() => onExportAnalyticsClick()}
                                                data-tip={!userHasExportAnalytics ? createToolTipText() : null}
                                                className={!userHasExportAnalytics ? styles['disabled-button'] : null}
                                            >
                                                Download Full Analytics
                                            </Larky.Button>
                                        </div>
                                    </>
                                )}
                                {isUpdate && !initialValues.isDraft && nudgeType === 1 &&(
                                    <>
                                        <div className={styles['center-button-row']}>
                                            <Larky.Button
                                                size="lg"
                                                onClick={showAnalyticsPopup}
                                            >
                                                Show Full Analytics
                                            </Larky.Button>
                                        </div>
                                    </>
                                )}
                            </div>
                            {(!isDisabled(false) && userHasMLFeatureFlag) || (userIsViewOnly() && userHasMLFeatureFlag) ? (
                                <NudgeScore
                                    title={formik.values.title}
                                    message={formik.values.body}
                                    isFloating
                                    loading={nudgeScoreCalculating}
                                    nudgeScoreData={nudgeScoreData}
                                    toolTipText={(true) ? 'Your implementation doesn’t include Nudge Score. Please contact support@larky.com for more information.' : null}
                                    onButtonClick={recheckscore}
                                    featureflag={userHasMLFeatureFlag}
                                />
                            ) : null}

                            {/*<div className={styles['center-button-row']}>*/}
                            {/*    <Larky.Button*/}
                            {/*    onClick={() => recheckscore()}*/}
                            {/*    data-tip={!userHasMLFeatureFlag ? 'Your implementation doesn’t include Nudge Score. Please contact support@larky.com for more information.' : null}*/}
                            {/*    className={!userHasMLFeatureFlag ? styles['disabled-button'] : null}*/}
                            {/*    > Recheck Score </Larky.Button>*/}
                            {/*</div>*/}

                            <NotificationPreview
                                title={formik.values.title}
                                message={formik.values.body}
                                isFloating
                            />
                        </CustomSection>
                    </Col>



                </Row>
            </form>
            {isUpdate && !initialValues.isDraft && initialValues.nudgeType === 1 && (
                <AnalyticsModal
                    organizationId={organizationId}
                    nudgeId={getAnalyticsModalParams().id}
                    actionId={actionId}
                    downloadAnalyticsParams={getAnalyticsModalParams()}
                    show={showModal}
                    callback={hideModalCallback}
                    title="Nudge Analytics"
                    text={currentNudgeMsg ? currentNudgeMsg.name : null}
                />
            )}
            {(
                <NudgeAssistModal
                    organizationId={organizationId}
                    currentnudgetitle={formik.values.title}
                    currentnudgebody={formik.values.body}
                    show={showNudgeAssistModal}
                    callback={hideNudgeAssistModalCallback}
                    title="Nudge Assist"
                    onOk={handleOk}
                    key={modalKey}
                />
            )}
    </div>
);
}

CreateOrUpdateNudge.propTypes = {
    organizationId: PropTypes.number,
};

CreateOrUpdateNudge.defaultProps = {
    organizationId: null,
};

export default CreateOrUpdateNudge;
