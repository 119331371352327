// Dependencies
import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { Userpilot } from 'userpilot';

import { useMachine } from '@xstate/react';

// Styles
import './App.scss';

// Libs
import ApolloClientProvider from 'providers/ApolloClientProvider';

import InitialLoader from 'components/InitialLoader/InitialLoader';
import { NotificationMachineContext } from 'contexts/notificationMachineContext.ts';

// Providers
import NotificationsProvider from 'providers/NotificationsProvider';

// Components
import routes from 'Routes';
import { getUser, getUserFeature } from './Utils';
import CustomNavBar from './components/Navbar/CustomNavbar';
import HeaderPublic from './components/Navbar/HeaderPuclic';
import Footer from './components/Footer/Footer';
import Pilot from './components/Pilot/Pilot';

// Guards
import RoleGuard from './guards/RoleGuard';
import PrivateRoute from './guards/PrivateRoute';
import PublicRoute from './guards/PublicRoute';
import ApiKeyGuard from './guards/ApiKeyGuard';

// Contexts
import { AuthMachineContext } from './contexts/auth-machine.context.ts';

// Machines
import { authMachine } from './machines/auth.machine.ts';
import SessionTimeout from './components/SessionTimeout/SessionTimeout';
import useAuthorization from './hooks/useAuthentication';

function App() {
  // Initialize Userpilot
  Userpilot.initialize('NX-6b572d7f');

  const authMachineValues = useMachine(authMachine);

  const isInitial = authMachineValues[0].matches('initial');
  const isLoggedIn = authMachineValues[0].matches('loggedIn');
  const [authorizedCheck, setIsAuthorizedCheck] = useState(false);
  const onResolved = (data) => {
    setIsAuthorizedCheck(data);
  };
  useAuthorization.isAuthorized().then((res) => onResolved(res));

  const [userHasNudgesRead, setUserHasNudgesRead] = useState(false);
  const [userHasCampaignsRead, setUserHasCampaignsRead] = useState(false);
  const [userHasSegmentsRead, setUserHasSegmentsRead] = useState(false);
  const [userHasLocationsRead, setUserHasLocationsRead] = useState(false);

  const [userHasNudgesWrite, setUserHasNudgesWrite] = useState(false);
  const [userHasCampaignsWrite, setUserHasCampaignsWrite] = useState(false);
  const [userHasSegmentsWrite, setUserHasSegmentsWrite] = useState(false);
  const [userHasLocationsWrite, setUserHasLocationsWrite] = useState(false);

  const [userHasOrgSpecificAdminFeatures, setUserHasOrgSpecificAdminFeatures] = useState(false);
  const [userHasPartnerManagementFeatures, setUserHasPartnerManagementFeatures] = useState(false);
  const user = getUser();

  useEffect(async () => {
    const hasNudgeFeatureGeoRead = await getUserFeature('geolocations');
    const hasNudgeFeatureTimeRead = await getUserFeature('nudges_timed');
    const hasCampaignFeatureRead = await getUserFeature('campaigns');
    const hasSystemSegmentsFeatureRead = await getUserFeature('system_segmentation');
    const hasUserSegmentsFeatureRead = await getUserFeature('user_segmentation');
    const hasGeoLocationFeatureRead = await getUserFeature('geolocations');
    const hasOrgSpecificAdminFeatures = await getUserFeature('user_org_management_admin');

    const hasNudgeFeatureGeoWrite = await getUserFeature('geolocations', 2);
    const hasNudgeFeatureTimeWrite = await getUserFeature('nudges_timed', 2);
    const hasCampaignFeatureWrite = await getUserFeature('campaigns', 2);
    const hasSystemSegmentsFeatureWrite = await getUserFeature('system_segmentation', 2);
    const hasUserSegmentsFeatureWrite = await getUserFeature('user_segmentation', 2);
    const hasPartnerManagementFeature = await getUserFeature('partner_self_serve')

    await setUserHasOrgSpecificAdminFeatures(hasOrgSpecificAdminFeatures);
    await setUserHasPartnerManagementFeatures(hasPartnerManagementFeature);
    await setUserHasNudgesRead(hasNudgeFeatureTimeRead || hasNudgeFeatureGeoRead);
    await setUserHasCampaignsRead(hasCampaignFeatureRead);
    await setUserHasSegmentsRead(hasSystemSegmentsFeatureRead || hasUserSegmentsFeatureRead);
    await setUserHasLocationsRead(hasGeoLocationFeatureRead);

    await setUserHasNudgesWrite(hasNudgeFeatureTimeWrite || hasNudgeFeatureGeoWrite);
    await setUserHasCampaignsWrite(hasCampaignFeatureWrite);
    await setUserHasSegmentsWrite(hasSystemSegmentsFeatureWrite || hasUserSegmentsFeatureWrite);
    await setUserHasLocationsWrite(hasNudgeFeatureGeoWrite);
  }, [user]);

  const Routes = routes.map(({
    isPrivate,
    exact,
    path,
    menuItem,
    children,
    apiKeyCheck,
    modal,
    isAdmin,
  }) => {
    if (typeof isPrivate === 'undefined') {
      return (
        <Route key={path} exact={exact} path={path}>
          <div id="container" className={modal ? '' : 'centered'}>
            {(typeof menuItem !== 'undefined' && !modal) && (
            <CustomNavBar
              menuItem={menuItem}
              userHasNudgesRead={userHasNudgesRead}
              userHasCampaignsRead={userHasCampaignsRead}
              userHasSegmentsRead={userHasSegmentsRead}
              userHasLocationsRead={userHasLocationsRead}
              userHasNudgesWrite={userHasNudgesWrite}
              userHasCampaignsWrite={userHasCampaignsWrite}
              userHasSegmentsWrite={userHasSegmentsWrite}
              userHasLocationsWrite={userHasLocationsWrite}
              userHasOrgSpecificAdminFeatures={userHasOrgSpecificAdminFeatures}
              userHasPartnerManagementFeatures={userHasPartnerManagementFeatures}
            />
            )}
            <ApiKeyGuard check={apiKeyCheck}>
              <NotificationsProvider value="">
                {children}
              </NotificationsProvider>
            </ApiKeyGuard>
            {!modal && <Footer />}
          </div>
        </Route>
      );
    }

    return (
      <Route key={path} exact={exact} path={path}>
        <div id="container" className={modal ? '' : 'centered'}>
          {isPrivate ? (
            <PrivateRoute isLoggedIn={isLoggedIn} isAuth={authorizedCheck}>
              <RoleGuard admin={isAdmin}>
                {(typeof menuItem !== 'undefined' && !modal) && (
                <CustomNavBar
                  menuItem={menuItem}
                  userHasNudgesRead={userHasNudgesRead}
                  userHasCampaignsRead={userHasCampaignsRead}
                  userHasSegmentsRead={userHasSegmentsRead}
                  userHasLocationsRead={userHasLocationsRead}
                  userHasNudgesWrite={userHasNudgesWrite}
                  userHasCampaignsWrite={userHasCampaignsWrite}
                  userHasSegmentsWrite={userHasSegmentsWrite}
                  userHasLocationsWrite={userHasLocationsWrite}
                  userHasOrgSpecificAdminFeatures={userHasOrgSpecificAdminFeatures}
                  userHasPartnerManagementFeatures={userHasPartnerManagementFeatures}
                />
                )}
                <NotificationsProvider value="">
                  <ApiKeyGuard check={apiKeyCheck}>
                    {children}
                  </ApiKeyGuard>
                </NotificationsProvider>
                {!modal && <Footer />}
              </RoleGuard>
            </PrivateRoute>
          ) : (
            <PublicRoute isAuth={isLoggedIn}>
              {!modal && <HeaderPublic />}
              <NotificationsProvider value="">
                <ApiKeyGuard check={apiKeyCheck}>
                  {children}
                </ApiKeyGuard>
              </NotificationsProvider>
              {!modal && <Footer />}
            </PublicRoute>
          )}
        </div>
      </Route>
    );
  });

  return (
    <ApolloClientProvider>
      <NotificationMachineContext.Provider value="">
        <AuthMachineContext.Provider value={authMachineValues}>
          <Pilot />
          {
            isInitial ? (<InitialLoader />) : (
              <>
                <Router>
                  <Switch>
                    {Routes}
                    <Route render={() => <Redirect to="/" />} />
                  </Switch>
                </Router>
                <SessionTimeout />
              </>
            )
          }
        </AuthMachineContext.Provider>
      </NotificationMachineContext.Provider>
    </ApolloClientProvider>
  );
}

export default App;
