import React from 'react';
import PropTypes from 'prop-types';

import { printPrettyDateFromDatetime, parseNudgeStatus, printPrettyTimeFromDatetime } from 'Utils';

import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import RowBadge from 'components/CustomTable/RowBadge/RowBadge';
import styles from './NudgeRow.module.scss';
import RowActionsDropDown from '../../../../components/CustomTable/RowActionsDropdown/RowActionsDropdown';
import moment from "moment-timezone";

const NudgeRow = ({
  data,
  handleSelect,
  handleArchiveRow,
  handleDeleteRow,
  handleCloneRow,
  isSelectable,
  hasEditPermission,
  handleShowAnalytics,
}) => {

      const getUserTimezone = () => {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    };

    const getDisplayTimezoneName = (timezone) => {
      const offset = moment.tz(timezone).format('Z');
      const abbreviation = moment.tz(timezone).format('z');
      // Create a more user-friendly name
      const timezoneMap = {
        'EDT': 'ET',
        'EST': 'ET',
        'CDT': 'CT',
        'CST': 'CT',
        'MDT': 'MT',
        'MST': 'MT',
        'PDT': 'PT',
        'PST': 'PT',
        // Add more mappings as needed
      };
      return timezoneMap[abbreviation] || `UTC${offset}`; // Default to UTC offset if no mapping found
    };

    const timezone = getUserTimezone();
    const TIME_ZONE = getDisplayTimezoneName(timezone);
  function displayLocations(locationsData) {
    // there may be nulls coming back in the response that could mess with the array operations below
    let nonNullLocations = locationsData.filter(Boolean);
    nonNullLocations.sort();
    if (nonNullLocations.length > 4) {
      const numLocations = nonNullLocations.length - 3;
      const message = `, plus ${numLocations} more`;
      nonNullLocations = nonNullLocations.slice(0, 3).join(', ').concat(message);
      return nonNullLocations;
    }

    return nonNullLocations.join(', ');
  }

  const {
    sent, isDraft, archived, type, dateTimeRangeStart, dateTimeRangeEnd, messageCategoryValue,
  } = data;

  const createDisplayMessage = (data) => {
    let messageDisplay = data.message;
    if (data.message && (data.message.length > 45)) {
      messageDisplay = `${data.message.slice(0, 44)}...`;
    }

    return messageDisplay;
  };

  const displayNudgeType = (type) => {
    if (type === 'Time-based') {
      return 'Time';
    } if (type === 'Geolocation') {
      return 'Geo';
    }
    return '';
  };

  const displayGeoNudgeSendDetails = (startDate, endDate) => {
    if ((isDraft || archived) && (!startDate || !endDate)) {
      if (startDate !== null && endDate === null) {
        return `Start: ${printPrettyDateFromDatetime(startDate)}; End: No Ending`;
      } if (startDate === null && endDate != null) {
        return `Start: Not Set; End: ${printPrettyDateFromDatetime(endDate)}`;
      }
      return 'Start: Not Set; End: No Ending';
    }
    if (endDate !== null) {
      return `Start: ${printPrettyDateFromDatetime(startDate)}; End: ${printPrettyDateFromDatetime(endDate)}`;
    }
    return `Start: ${printPrettyDateFromDatetime(startDate)}; No Ending`;
  };

  const createNudgeOptionList = () => {
    const listOfOptions = [];
    listOfOptions.push({
      label: hasEditPermission && !data.archived ? 'View/Edit nudge' : 'View nudge',
      value: 'edit',
    });
    if (handleCloneRow && hasEditPermission) {
      listOfOptions.push({ label: null, value: null, isLineBreak: true });
      listOfOptions.push({ label: 'Clone nudge', value: 'clone', isLine: true });
    }
    if (handleArchiveRow && hasEditPermission) {
      listOfOptions.push({ label: null, value: null, isLineBreak: true });
      listOfOptions.push({ label: data.archived ? 'Unarchive nudge' : 'Archive nudge', value: 'archive' });
    }
    if (handleDeleteRow && hasEditPermission) {
      // eslint-disable-next-line no-unused-expressions
      handleArchiveRow ? listOfOptions.push({
        label: 'Delete nudge',
        value: 'delete',
      }) : listOfOptions.push({ label: null, value: null, isLineBreak: true }, {
        label: 'Delete nudge',
        value: 'delete',
      });
    }
    return listOfOptions;
  };

  const parsedNudgeStatus = parseNudgeStatus(sent, isDraft, archived, type, dateTimeRangeStart, dateTimeRangeEnd);

  return (
    <tr className={styles.customRow}>
      {isSelectable && (
        <td>
          <CustomCheckbox
            value={data.selected}
            onChange={(value) => handleSelect(data.id, value)}
          />
        </td>
      )}
      <td className={styles.customCell}>
        {parsedNudgeStatus
                    && <RowBadge color={parsedNudgeStatus.color}>{parsedNudgeStatus.value}</RowBadge>}
      </td>
      <td>{displayNudgeType(data.type)}</td>
      <td>{data.messageCategoryValue}</td>
      <td>{data.name}</td>
      <td>{printPrettyDateFromDatetime(data.created)}</td>
      <td>
        <b>{data.title}</b>
        {' '}
        <p>{createDisplayMessage(data)}</p>
      </td>
      {data.type === 'Geolocation' && (
        <td>
          {' '}
          {(data.dateTimeRangeStart || isDraft) ? displayGeoNudgeSendDetails(data.dateTimeRangeStart, data.dateTimeRangeEnd) : null}
          <p>{`Locations: ${displayLocations(data.locations)}`}</p>
        </td>
      )}
      {data.type === 'Time-based' && (
        <td>
          {data.timeBasedLaunchDate ? `Scheduled Date: ${printPrettyDateFromDatetime(data.timeBasedLaunchDate)}` : isDraft ? 'Scheduled Date: Not Set' : null}
          <p>{data.timeBasedLaunchDate ? `Scheduled Time: ${printPrettyTimeFromDatetime(data.timeBasedLaunchDate)} ${TIME_ZONE}` : isDraft ? 'Scheduled Time: Not Set' : null}</p>


        </td>
      )}
      {data.type === null && (
        <td />
      )}
      <td>
        <RowActionsDropDown
          editRowPath={`/nudges/edit/${data.id}`}
          archived={data.archived}
          onArchiveRow={handleArchiveRow}
          onDeleteRow={handleDeleteRow}
          onCloneRow={handleCloneRow}
          onShowAnalyticsRow={handleShowAnalytics}
          hasEditPermission={hasEditPermission}
          placeholder="Actions..."
          options={createNudgeOptionList()}
        />
      </td>
    </tr>
  );
};

NudgeRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    archived: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
    sent: PropTypes.bool.isRequired,
  }).isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleArchiveRow: PropTypes.func,
  handleCloneRow: PropTypes.func,
  handleShowAnalytics: PropTypes.func,
  handleDeleteRow: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool,
  hasEditPermission: PropTypes.bool,
};

NudgeRow.defaultProps = {
  isSelectable: false,
  hasEditPermission: false,
  handleArchiveRow: () => null,
  handleCloneRow: () => null,
  handleShowAnalytics: () => null,
};

export default NudgeRow;
