import { useNotification } from 'components/Notification/Notification';
import { PAGINATION } from 'Constants';

class SegmentService {
  /**
   * Find segment by id
   * @param {Object} query
   * @param {Number} id
   * @returns {Promise}
   */
  static async index(query, segmentId) {
    const { data } = await query.refetch({ segmentId });
    return data.getSegment;
  }

  static async getUserSegmentByParentId(query, parentId) {
    const { data } = await query.refetch({ parentId });
    return data.getUserSegmentByParentId;
  }

  /**
   * Get all segments
   * @param {Object} query
   * @param {String} orderBy
   * @param {String} sortOrder
   * @param {Number} rows
   * @param {Number} start
   * @param {Object} params
   * @returns {Promise}
   *
   * params contains the filter to be applied to the search. for the 'segmentType' filter is
   * possible to filer by multiple types by appending the using a dash (-) between the
   * parameters like this:
   * { segmentType: 'user-file' }, where user is one type and file is another
   */
  static async searchSegments(
    query,
    orderBy,
    sortOrder,
    start,
    params,
    rows = PAGINATION.ITEMS_PER_PAGE,
  ) {
    const { data } = await query.refetch({
      orderBy,
      sortOrder,
      rows,
      start,
      searchParam: params,
    });
    return data.searchSegments;
  }

  /**
   * Get segments - backend takes care of filtering by organization_id,
   * so there's no need to pass it
   * @param {Object} query
   * @param {Object} params {
   *   'id': "true", 'segment_name': "value", 'segment_type': "value", 'compute_type': "value",
   *   'filter_status': "value", 'filter_keyword': "value", 'filter_created_after': "value"
   * }
   * @returns {Promise}
   */
  static async getSegments(query, params) {
    const { data } = await query.refetch({ searchParam: params });
    return data.getSegments;
  }

  /**
   * Create new segment
   * @param {Promise} mutation
   * @param {Object} input
   * @returns {Promise}
   */
  static async create(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.createSegment;
  }

  static async createFileDataSegment(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data;
  }

  static async update(mutation, segmentId, input) {
    const { data } = await mutation({ variables: { segmentId, input } });
    return data.updateSegment;
  }

  /**
   * Create new system segment
   * @param {Promise} mutation
   * @param {Object} input
   * @returns {Promise}
   */
  static async createSystemSegment(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.createSystemSegment;
  }

  /**
   * Calculate membership of a user segment
   * @param {Object} query
   * @param {String} organizationId
   * @param {String} userId
   * @param {Number} membershipLimit
   * @param {[Number]} segmentIncluded
   * @param {[Number]} segmentExcluded
   * @returns {Promise}
   */
  static async calculateUserSegment(
    query,
    organizationId,
    userId,
    membershipLimit,
    segmentIncluded,
    segmentExcluded,
  ) {
    const { data } = await query.refetch({
      organizationId,
      userId,
      membershipLimit,
      segmentIncluded,
      segmentExcluded,
    });
    return data.calculateUserSegment;
  }

  /**
   * Delete a segment by id
   * @param {Object} mutation
   * @param {[Number]} id
   * @returns {Any}
   */
  static async delete(mutation, query, segmentId) {
    const notification = useNotification();
    const res = await query.refetch({ segmentId });
    let canBeDeleted = true;

    if (res.data && res.data.checkWhoIsUsingSegmentById && res.data.checkWhoIsUsingSegmentById.length > 0) {
      canBeDeleted = false;
      const { dialogContent, dialogSubtitle } = buildDeleteDialogContent(res.data.checkWhoIsUsingSegmentById.slice(0, 4));
      notification.fullscreenDialog(
        'Attention!',
        dialogSubtitle,
        dialogContent,
        'OK',
        () => {},
        null,
        null,
        true,
      );
    }

    if (canBeDeleted) {
      const des = await notification.fullscreenDialogConfirm(
        'Are you sure you want to delete this segment?',
        'Delete segment',
      );

      if (des) {
        const { data } = await mutation({ variables: { id: segmentId } });
        return data.deleteSegment;
      }
    }

    function buildDeleteDialogContent(responseData) {
      const dialogContent = [];
      let nudgeCount = 0;
      let segmentCount = 0;

      function handleType(value) {
        if ('segmentUserType' in value) {
          if (value.segmentUserType === 'Nudge') {
            nudgeCount += 1;
            dialogContent.push(`Nudge: ${value.segmentUserName} `);
          }
          if (value.segmentUserType === 'Segment') {
            segmentCount += 1;
            dialogContent.push(`Nudge: ${value.segmentUserName} `);
          }
          if (value.segmentUserType === 'Campaign') {
            segmentCount += 1;
            dialogContent.push(`Campaign: ${value.segmentUserName} `);
          }
        }
      }
      responseData.forEach(handleType);
      let dialogSubtitle = 'This segment is in use by the following ';
      if (nudgeCount > 0) {
        const nudge = nudgeCount === 1 ? 'nudge ' : 'nudges ';
        dialogSubtitle += nudge;
      }
      if (segmentCount > 0) {
        let segment = nudgeCount > 0 ? 'And' : '';
        segment = segmentCount === 1 ? 'segment ' : 'segments ';
        dialogSubtitle += segment;
      }
      dialogSubtitle += "and can't be deleted:";
      return { dialogContent, dialogSubtitle };
    }
    return null;
  }

  /*
   * Calculate membership of a system segment
   * @param {Object} query
   * @param {String} organizationId
   * @param {String} userId
   * @param {Number} messageId
   * @param {String} nudgeActivity
   * @param {Number} timeframe
   * @returns {Promise}
   */
  static async calculateSystemSegment(
    query,
    organizationId,
    userId,
    messageId,
    nudgeActivity,
    timeframe,
  ) {
    const { data } = await query.refetch({
      organizationId,
      userId,
      messageId,
      nudgeActivity,
      timeframe,
    });
    return data.calculateSystemSegment;
  }

  /**
   * Find segment by id and refresh calculations
   * @param {Object} query
   * @param {Number} id
   * @returns {Promise}
   */
  static async refreshUserSegment(query, segmentId) {
    const { data } = await query.refetch({ segmentId });
    return data.refreshUserSegment;
  }

  /**
   *  Recalculates a file (federated ID) segment which may not be stored in the database
   */
  static async calculateSegmentFileData(
    query,
    organizationId,
    userId,
    federationId,
    membershipLimit,
  ) {
    const { data } = await query.refetch({
      organizationId,
      userId,
      federationId,
      membershipLimit,
    });

    return data.calculateFileSegment;
  }

  static async countAllSegments(query, organizationId, userId, nudgeActivity, messageId = null, timeframe = null) {
    const { data } = await query.refetch({ organizationId, userId, nudgeActivity, messageId, timeframe });
    return data.countAllSegments;
  }
}

export default SegmentService;
